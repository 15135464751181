//const { ThisBound } = require("@iflb/lib");
const ducts = require("@iflb/ducts-client");

export let YhtDuct = class extends ducts.Duct {
    constructor() {
        super();
        this._eventHandlers = {};
    }

    _onopen(self, event) {
        for(const [eventName, eventId] of Object.entries(self.EVENT).filter((e) => (e[1]>=1000))){
            self._eventHandlers[eventId] = { success: [], error: [], complete: [] };
            self._eventHandler[eventId] = (rid,eid,data) => {
                if('success' in data){
                    if(data.success) {
                        for(const handler of self._eventHandlers[eventId].success) handler(rid,eid,data.content);
                    } else {
                        for(const handler of self._eventHandlers[eventId].error) handler(rid,eid,data.content);
                    }
                    for(const handler of self._eventHandlers[eventId].complete) handler(rid,eid,data.content);
                } else {
                    console.warning(`YHT-DUCTS server response for '${eventName}' is not in a valid format:`, data);
                }
            }
        }
        super._onopen(self, event);
    }

    addEventHandler(self, eventId, { success, error, complete }) {
        if(!(eventId in self._eventHandlers)) {
            console.error('Cannot add event handler for invalid ID:', eventId);
        } else {
            if(!self._eventHandlers[eventId].success.includes(success)){
                self._eventHandlers[eventId].success.push(success);
            }
            if(error && !self._eventHandlers[eventId].error.includes(error)){
                self._eventHandlers[eventId].error.push(error);
            }
            if(complete && !self._eventHandlers[eventId].complete.includes(complete)){
                self._eventHandlers[eventId].complete.push(complete);
            }
        }
    }

    removeEventHandler(self, eventId, { success, error, complete }) {
        for(const i in self._eventHandlers.success[eventId]){
            if(self._eventHandlers[eventId].success[i]==success) {
                delete self._eventHandlers[eventId].success[i];
                break;
            }
        }
        if(error) {
            for(const i in self._eventHandlers[eventId].error){
                if(self._eventHandlers[eventId].error[i]==error) {
                    delete self._eventHandlers[eventId].error[i];
                    break;
                }
            }
        }
        if(complete) {
            for(const i in self._eventHandlers[eventId].complete){
                if(self._eventHandlers[eventId].complete[i]==complete) {
                    delete self._eventHandlers[eventId].complete[i];
                    break;
                }
            }
        }
    }
}

export default {
    YhtDuct
}
